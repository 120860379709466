<template>
  <b-modal
    id="modal-checkout-warning"
    centered
    scrollable
  >
    <template #modal-header>
      <div>Hi, masih ada transaksi yang belum kamu selesaikan loh!</div>
    </template>
    <div class="row" v-for="order in unfinishedOrderList" :key="order.id">
      <div class="col-6 order-number">{{ order.order_number }}</div>
      <div class="col-6 text-right order-price">{{ $helpers.format.price(order.total) }}</div>
      <template v-for="seller in order.sellers">
        <template v-for="item in seller.items">
          <div class="col-6 mb-1" :key="item.id">
            <div class="bold" v-if="item.product_name">{{ item.product_name }}</div>
            <div class="item-desc d-lg-flex" v-if="!item.bundled_product">
              <div>Color : {{ item.color }}</div>
              <div class="ml-lg-3">Size : {{ item.size }}</div>
            </div>
          </div>
          <div class="item-qty col-6 mb-1 text-right" :key="item.id">
            {{ item.quantity }}x
          </div>
        </template>          
      </template>
    </div>
    <div class="mt-3">
      Apa kamu mau membatalkan transaksi tersebut?<br>  
      <span class="text-grey">Kamu tetap bisa melanjutkan transaksi ini, walau transaksi tersebut dibatalkan.</span>
    </div>
    <template #modal-footer>
      <b-button class="btn-wz-cancel" @click="$emit('proceedPayment')">Tidak</b-button>
      <b-button class="btn-wz-proceed" @click="cancelAllUnfinishedOrder">Ya</b-button>
    </template>
  </b-modal>
</template>
<script>
import { toaster } from "@/_helpers";
export default {
  name: "ModalCheckoutWarning",
  props: ["unfinishedOrderList"],
  methods: {
    async cancelAllUnfinishedOrder() {
      try {
        const res = await this.$api.order.cancelAllOrder("checked")
        if (res.status == 200) this.$emit("proceedPayment")
        else toaster.make(res.data.message, "danger")
      } catch (error) {
        console.error(error)
      }
    },
  }
};
</script>
<style scoped>
button {
  padding: 8px 12px;
  text-transform: uppercase;
  font-size: 12px !important;  
  letter-spacing: .5px !important;
}
.order-number, .order-price {
  font-size: 18px;
  font-weight: bold;
}
* .row:not(:first-child) {
  margin-top: .75rem;
}
.item-desc {
  font-size: 14px;
}
.item-qty {
  color: #828282;
}
</style>
